import { EmitirErro } from "../../../services/EmitirErro";
import { useCallback, useContext, useEffect, useState } from "react";
import { api } from "../../../services/apiAxios";
import { urlLimitesETaxas } from "../../../services/urls";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../../contexts/globalContext";
import { mask, unMask } from "../../../utils/MascaraDeCampos";
import { message } from "antd";
interface IData {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "cnpj": string,
    "nome": string,
    "cpf": string,
    "email": string,
    "telefoneFixo": string,
    "celular": string,
    "municipioIdIbge": number,
    "municipio": {
        "nome": string,
    },
    "taxaDeJuros": number | null,
    "limitePreAprovado": number | null
}

export const useLimitesETxById = () => {

    let { id } = useParams();

    const { theme, updateTheme } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [msgEntreEmContato, setMsgEntreEmContato] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();

    const [data, setData] = useState<IData>();

    //setando dados das cidades no selected
    const getDataScreen = useCallback(async (id: string) => {

        try {
            setLoading(true);
            let { data } = await api.get(urlLimitesETaxas + '/' + id);

            setData(data)

            setLoading(false);

        } catch (error) {
            EmitirErro(error, true, 'municipios_dkwdfaiweyi')
        }

    }, []);

    useEffect(() => {

        if (theme === 'dark') {
            updateTheme('light')
            window.location.reload();
        }

    }, [theme])

    useEffect(() => {

        if (id) {

            const idParam = String(id);

            if (typeof idParam === 'string') {

                getDataScreen(idParam);
            }

        }

    }, [id])

    const maskCNPJ = useCallback((value: any) => {
        if (!value) return '';

        const originalValue = unMask(value);
        const maskedValue = mask(originalValue, [
            "99.999.999/9999-99"
        ]);

        return maskedValue

    }, []);

    const maskCPF = useCallback((value: any) => {
        if (!value) return '';

        const originalValue = unMask(value);
        const maskedValue = mask(originalValue, [
            "999.999.999-9",
            "999.999.999-99"
        ]);

        return maskedValue

    }, []);

    const maskTelefone = useCallback((value: any) => {
        if (!value) return '';

        const originalValue = unMask(value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        return maskedValue

    }, []);

    const textCopy = useCallback((text: string) => {

        function fallbackCopyTextToClipboard(text: string) {
            let textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand('copy');
                let msg = successful ? 'successful' : 'unsuccessful';
                // console.log('Fallback: Copying text command was ' + msg);
                messageApi.open({
                    type: 'success',
                    content: 'Copiado!',
                  });
            } catch (err) {
                // console.error('Fallback: Oops, unable to copy', err);
            }
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            // console.log('Async: Copying to clipboard was successful!');
            messageApi.open({
                type: 'success',
                content: 'Copiado!',
              });
        }, function (err) {
            // console.error('Async: Could not copy text: ', err);
        });


    }, []);


    return {
        loading,
        theme,
        msgEntreEmContato,
        setMsgEntreEmContato,

        data,
        maskCNPJ,
        maskCPF,
        maskTelefone,
        textCopy,
        contextHolder,
    }
}
